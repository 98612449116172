import React, { useEffect } from 'react';
import {
  withWidth,
  Button,
  TextField,
  Box,
  Typography,
  Paper,
  Divider,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import * as api from '../../services/apiService';
import ClearIcon from '@material-ui/icons/Clear';
import { selectCodesStyle } from './Styles';
import emptySaerch from '../../assets/emptySearch.png';
import emptySaerchDark from '../../assets/dark/searchDark.png';

interface SelectCodesPropsType {
  diagnosis: any[];
  selectedCptCodes: any;
  selectedCodeObjCptCodes: any;
  selectedCodeObjDiagnosis: any;
  setSelectedCptCodes: any;
  index: number;
  setDiagnosis: (diagnosis: string[]) => void;
  searchPath: string;
  closeDialog: (close: boolean) => void;
}

const SelectCodes: React.FC<SelectCodesPropsType> = (props) => {
  const classes = selectCodesStyle();
  const {
    diagnosis,
    selectedCptCodes,
    setSelectedCptCodes,
    setDiagnosis,
    selectedCodeObjCptCodes,
    selectedCodeObjDiagnosis,
    index,
    searchPath,
    closeDialog
  } = props;
  const isDarkTheme = useTheme().palette.type === 'dark';
  const [selected, setSelected] = React.useState<any[]>(
    searchPath === 'cpt_lookups'
      ? index !== -1
        ? selectedCodeObjCptCodes.arr
        : selectedCodeObjCptCodes
      : index !== -1
      ? selectedCodeObjDiagnosis.arr
      : selectedCodeObjDiagnosis
  );
  const [searchValue, setSearchValue] = React.useState<string>('');
  const [items, setItems] = React.useState<string[]>([]);
  const [performSearch, setPerformSearch] = React.useState<boolean>(false);

  const doSearch = React.useCallback(async () => {
    const { status_code: statusCode, data } = await api.onTermSearch(
      searchPath,
      searchValue
    );
    if (statusCode === api.status.SUCCESS) {
      setItems(data);
    }
  }, [searchPath, searchValue]);

  useEffect(() => {
    if (searchValue === '') {
      setItems([]);
    } else {
      doSearch();
    }
  }, [doSearch, searchValue]);

  const onSave = () => {
    if (searchPath === 'cpt_lookups') {
      if (index !== -1) {
        const newData = selectedCptCodes.slice();
        newData[index].arr = selected;
        setSelectedCptCodes(newData.map((item) => ({...item, quantity: item?.quantity || 1})));
      } else {
        setSelectedCptCodes(selected.map((item) => ({...item, quantity: item?.quantity || 1})));
      }
    } else {
      if (index !== -1) {
        const newData = diagnosis.slice();
        newData[index].arr = selected;

        setDiagnosis(newData);
      } else {
        setDiagnosis(selected);
      }
    }
    closeDialog(false);
  };

  const handleSearchChange = (event: any) => {
    setPerformSearch(true);
    setSearchValue(event.target.value);
  };

  const addCodes = (code: any) => {
    let codeExists = false;
    if (index !== -1) {
      for (let j = 0; j < selected.length; j++) {
        let element = selected[j];
        if (element.id === code.id) {
          codeExists = true;
        }
      }
    } else {
      for (let j = 0; j < selected.length; j++) {
        let element = selected[j];
        if (element.id === code.id) {
          codeExists = true;
        }
      }
    }

    if (index !== -1) {
      for (let j = 0; j < selected.length; j++) {
        let element = selected[j];
        if (element.id === code.id) {
          codeExists = true;
        }
      }
    } else {
      for (let j = 0; j < selected.length; j++) {
        let element = selected[j];
        if (element.id === code.id) {
          codeExists = true;
        }
      }
    }

    if (selected.length === 0) {
      setSelected((selected) => [...selected, code]);
    } else {
      if (codeExists) {
        setSelected(selected.filter((item) => item !== code));
      } else {
        setSelected((selected) => [...selected, code]);
      }
    }
  };

  const codeExists = (code: any) => {
    let codeExists = false;

    if (searchPath === 'cpt_lookups') {
      let cleanSelectedCptCodes = selectedCptCodes.filter(function () {
        return true;
      });
      for (let j = 0; j < cleanSelectedCptCodes.length; j++) {
        let element = cleanSelectedCptCodes[j];
        if (index !== -1) {
          for (let i = 0; i < element.arr.length; i++) {
            if (element.arr[i].id === code.id) {
              codeExists = true;
            }
          }
        } else {
          if (element.id === code.id) {
            codeExists = true;
          }
        }
      }
    } else {
      let cleanDiagnosis = diagnosis.filter(function () {
        return true;
      });
      for (let j = 0; j < cleanDiagnosis.length; j++) {
        let element = cleanDiagnosis[j];
        if (index !== -1) {
          for (let i = 0; i < element.arr.length; i++) {
            if (element.arr[i].id === code.id) {
              codeExists = true;
            }
          }
        } else {
          if (element.id === code.id) {
            codeExists = true;
          }
        }
      }
    }
    return codeExists;
  };

  const clearSearch = () => {
    if (searchValue !== '') {
      setSearchValue('');
      setItems([]);
    }
  };

  const inputProps = {
    endAdornment: (
      <InputAdornment position="end">
        <ClearIcon onClick={clearSearch} className={classes.cursor}></ClearIcon>
      </InputAdornment>
    )
  };

  return (
    <div className={classes.container}>
      <ClearIcon className={classes.icon} onClick={() => closeDialog(false)} />
      {searchPath === 'cpt_lookups' ? (
        <Typography className={classes.header} variant="h1">
          CPT Code
        </Typography>
      ) : (
        <Typography className={classes.header} variant="h1">
          Diagnosis (ICD-10)
        </Typography>
      )}
      <Box mb={1} className={classes.searchBox}>
        <TextField
          InputProps={searchValue.length > 0 ? inputProps : undefined}
          variant="outlined"
          label="Search"
          margin="normal"
          fullWidth
          value={searchValue}
          onChange={(event) => handleSearchChange(event)}
        />
      </Box>
      <Paper
        elevation={0}
        className={
          items.length > 0 ? classes.resultBoxWithBorder : classes.resultBox
        }
      >
        {items.length > 0 ? (
          items.map((item: any) => (
            <Box key={item.id}>
              <Box className={classes.searchBox}>
                <FormControlLabel
                  control={
                    <Checkbox
                      classes={{
                        root: classes.root,
                        checked: classes.checked
                      }}
                      value={codeExists(item)}
                      className={classes.checkBoxColor}
                      onClick={() => addCodes(item)}
                    />
                  }
                  label={
                    <div>
                      <Typography variant="h6"> {item.code}</Typography>
                      <Typography
                        variant="caption"
                        className={classes.itemValue}
                      >
                        {item.value}
                      </Typography>
                    </div>
                  }
                />
              </Box>
              <Divider></Divider>
            </Box>
          ))
        ) : (
          <Box>
            {performSearch ? (
              <Box mb={2} className={classes.emptyView}>
                <Typography className={classes.centerText} variant="caption">
                  <img
                    src={!isDarkTheme ? emptySaerch : emptySaerchDark}
                    alt="no data"
                  ></img>
                  <Box>
                    {' '}
                    There are no similar results! please try another keyword.
                  </Box>
                </Typography>
              </Box>
            ) : (
              <Box mb={2} className={classes.emptyView}>
                <Typography className={classes.centerText} variant="caption">
                  <img
                    src={!isDarkTheme ? emptySaerch : emptySaerchDark}
                    alt="no data"
                  ></img>
                  <Box> Start typing to get results!</Box>
                </Typography>
              </Box>
            )}
          </Box>
        )}
      </Paper>
      <Box pt={3} className={classes.buttonsContainer}>
        <Button
          onClick={() => onSave()}
          variant="contained"
          color="secondary"
          className={classes.footerButton}
        >
          Add
        </Button>
      </Box>
    </div>
  );
};
export default withWidth()(SelectCodes);
