import {
  AppBar,
  Box,
  Button,
  Grid,
  Paper,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import { Link } from 'react-router-dom';

import React, { useEffect, useState } from 'react';
import Free from '../../../assets/PromotionalSite/Plans/FDark.svg';
import Busy from '../../../assets/PromotionalSite/Plans/BDark.svg';
import Moderate from '../../../assets/PromotionalSite/Plans/MDark.svg';
import Unlimited from '../../../assets/PromotionalSite/Plans/UDark.svg';

import Footer from '../../../components/footer/Footer';
import plansStyle from './Styles';
import history from '../../../utils/history';
import TabPanel from '../../../components/TabPanel/TabPanel';
import * as api from '../../../services/apiService';
import planTopImg from '../../../assets/img/MS-busy-plan-top-img.png';
import RequestDemo from '../../../components/RequestDemo/RequestDemo';

const Plans = () => {
  const classes = plansStyle();
  const theme = useTheme();
  const isDarkTheme = useMediaQuery('(prefers-color-scheme: dark)');
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [monthlyPlans, setMonthlyPlans] = useState<any[]>([]);
  const [annualPlans, setAnnualPlans] = useState<any[]>([]);
  const matchesiPad = useMediaQuery(theme.breakpoints.down('lg'));
  const [yearlyPeriod, setYearlyPeriod] = useState<boolean>(false);
  const [isBusinessPlan, setIsBusinessPlan] = useState<boolean>(false);
  const handleChangeIndex = (event, newValue) => {
    setSelectedIndex(newValue);
  };
  const getPlans = React.useCallback(async () => {
    const { data, status_code: statusCode } = await api.getPlans();
    if (statusCode === api.status.SUCCESS) {
      setMonthlyPlans(data.monthly);

      setAnnualPlans(data.annual);
    }
  }, []);
  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`
    };
  }
  useEffect(() => {
    getPlans();
  }, []);

  const handlePlanPeriodChange = (e) => {
    setYearlyPeriod(e.target.checked);
  }

  const handlePlanTypeChange = (e) => {
    setIsBusinessPlan(e.target.checked)
  }

  return (
    <div>
      <section className="breadCrums">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="crumsHeading">
                <h1>Plans & Pricing</h1>
                <ul className="d-flex align-items-center justify-content-center">
                  <li><a onClick={() => history.push('/')} className="breadCrumsLink home pointer">Home </a></li>
                  <li><a onClick={() => history.push('/plans')} className="breadCrumsLink pointer"> &gt; Plan & Pricing</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="choosePlan" id="planPricing">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="planHeading">
                <h1>Choose the right plan for you</h1>
                <p>MS Logger provides a variety of annual or monthly plans to match your practice need.</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="tab-slider--nav">
                <div className="plan-toggle">
                  <p style={{ marginTop: '10px', marginRight: '20px', fontWeight: 600, fontSize: '20px' }}>Monthly</p>
                  <div className="toggle1">
                    <input onChange={handlePlanPeriodChange} checked={yearlyPeriod} name="yearlyPlans" type="checkbox" required></input>
                  </div>
                  <p style={{ marginTop: '10px', marginLeft: '20px', fontWeight: 600, fontSize: '20px' }}>Yearly</p>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <div className="planHeading">
                    <h1 style={{ marginTop: 30 }}>Individual or Business/Institution</h1>
                    <p>MS Logger offers tailored plans for individuals and businesses to suit your specific practice requirements.</p>
                  </div>
                </div>
              </div>

              <div className="tab-slider--nav">
                <div className="plan-toggle">
                  <p style={{ marginTop: '10px', marginRight: '20px', fontWeight: 600, fontSize: '20px' }}>Individual</p>
                  <div className="toggle1">
                    <input onChange={handlePlanTypeChange} checked={isBusinessPlan} name="planType" type="checkbox" required></input>
                  </div>
                  <p style={{ marginTop: '10px', marginLeft: '20px', fontWeight: 600, fontSize: '20px' }}>Business/Institution</p>
                </div>
              </div>
              <div className="tab-slider--container">

              {!isBusinessPlan ? (<>
                {!yearlyPeriod ? (<div id="tab1" className="tab-slider--body">
                  <div className="row">
                    <div className="col-xl-3 col-md-6 mb-xl-0 mb-5">
                      <div className="planContent activePlan">
                        <div className="planTopImg">
                          <img src={planTopImg} alt=""></img>
                        </div>
                        <p>{monthlyPlans[2]?.plan?.name}</p>
                        <h1>${monthlyPlans[2]?.cost}</h1>
                        <ul>
                          <li>Up to {monthlyPlans[2]?.features?.cases_per_month_limit} cases per month</li>
                          <li>Unlimited patients images</li>
                          <li>Up to {monthlyPlans[2]?.features?.registries_limit} registries</li>
                          <li>Automated RVUs/Reimbursement estimation</li>
                        </ul>
                        <div className="signUpPlan">
                          <Link to={`/signup/${monthlyPlans[2]?.braintree_plan_id}`} className="bgBlue form-control">Subscribe</Link>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-md-6 mb-xl-0 mb-5">
                      <div className="planContent">
                        <p>{monthlyPlans[0]?.plan?.name}</p>
                        <h1>${monthlyPlans[0]?.cost}</h1>
                        <ul>
                          <li>Up to {monthlyPlans[0]?.features?.cases_per_month_limit} cases per month</li>
                          <li>No patients images</li>
                          <li>No registries</li>
                          <li>No RVUs/Reimbursement estimation</li>
                        </ul>
                        <div className="signUpPlan">
                          <Link to="/signup" className="bgBlue form-control">Sign Up for Free plan</Link>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-md-6 mb-xl-0 mb-5">
                      <div className="planContent">
                        <p>{monthlyPlans[1]?.plan?.name}</p>
                        <h1>${monthlyPlans[1]?.cost}</h1>
                        <ul>
                          <li>Up to {monthlyPlans[1]?.features?.cases_per_month_limit} cases per month</li>
                          <li>Unlimited patients images</li>
                          <li>Up to {monthlyPlans[1]?.features?.registries_limit} registry</li>
                          <li>Automated RVUs/Reimbursement estimation</li>
                        </ul>
                        <div className="signUpPlan">
                          <Link to={`/signup/${monthlyPlans[1]?.braintree_plan_id}`} className="bgBlue form-control">Subscribe</Link>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-md-6 mb-xl-0 mb-md-5">
                      <div className="planContent">
                        <p>{monthlyPlans[3]?.plan?.name}</p>
                        <h1>${monthlyPlans[3]?.cost}</h1>
                        <ul>
                          <li>Unlimited cases per month</li>
                          <li>Unlimited patients images</li>
                          <li>Unlimited registries</li>
                          <li>Automated RVUs/Reimbursement estimation</li>
                        </ul>
                        <div className="signUpPlan">
                          <Link to={`/signup/${monthlyPlans[3]?.braintree_plan_id}`} className="bgBlue form-control">Subscribe</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>) : (
                  <div id="tab2" className="tab-slider--body">
                    <div className="row">
                      <div className="col-xl-3 col-md-6 mb-xl-0 mb-5">
                        <div className="planContent activePlan">
                          <div className="planTopImg">
                            <img src={planTopImg} alt=""></img>
                          </div>
                          <p>{annualPlans[2]?.plan?.name}</p>
                          <h1>${annualPlans[2]?.cost}</h1>
                          <ul>
                            <li>Up to {annualPlans[2]?.features?.cases_per_month_limit} cases per month</li>
                            <li>Unlimited patients images</li>
                            <li>Up to {annualPlans[2]?.features?.registries_limit} registries</li>
                            <li>Automated RVUs/Reimbursement estimation</li>
                          </ul>
                          <div className="signUpPlan">
                            <Link to={`/signup/${annualPlans[2]?.braintree_plan_id}`} className="bgBlue form-control">Subscribe</Link>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-3 col-md-6 mb-xl-0 mb-5">
                        <div className="planContent">
                          <p>{annualPlans[0]?.plan?.name}</p>
                          <h1>${annualPlans[0]?.cost}</h1>
                          <ul>
                            <li>Up to {annualPlans[0]?.features?.cases_per_month_limit} cases per month</li>
                            <li>No patients images</li>
                            <li>No registries</li>
                            <li>No RVUs/Reimbursement estimation</li>
                          </ul>
                          <div className="signUpPlan">
                            <Link to="/signup" className="bgBlue form-control">Sign Up for Free plan</Link>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-3 col-md-6 mb-xl-0 mb-5">
                        <div className="planContent">
                          <p>{annualPlans[1]?.plan?.name}</p>
                          <h1>${annualPlans[1]?.cost}</h1>
                          <ul>
                            <li>Up to {annualPlans[1]?.features?.cases_per_month_limit} cases per month</li>
                            <li>Unlimited patients images</li>
                            <li>Up to {annualPlans[1]?.features?.registries_limit} registry</li>
                            <li>Automated RVUs/Reimbursement estimation</li>
                          </ul>
                          <div className="signUpPlan">
                            <Link to={`/signup/${annualPlans[1]?.braintree_plan_id}`} className="bgBlue form-control">Subscribe</Link>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-3 col-md-6 mb-xl-0 mb-md-5">
                        <div className="planContent">
                          <p>{annualPlans[3]?.plan?.name}</p>
                          <h1>${annualPlans[3]?.cost}</h1>
                          <ul>
                            <li>Unlimited cases per month</li>
                            <li>Unlimited patients images</li>
                            <li>Unlimited registries</li>
                            <li>Automated RVUs/Reimbursement estimation</li>
                          </ul>
                          <div className="signUpPlan">
                            <Link to={`/signup/${annualPlans[3]?.braintree_plan_id}`} className="bgBlue form-control">Subscribe</Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>)}
              </>) : (
                <>
                <RequestDemo planType={yearlyPeriod ? 'Yearly' : 'Monthly'}/>
                </>
              )}

              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Plans;
