import { RouteComponentProps } from 'react-router-dom';
import * as H from 'history';
export type TParams = { caseId: string };

export interface procedureDataType {
  patient_first_name: string;
  patient_last_name: string;
  date_of_birth: string;
  mrn: null;
  age: null;
  primary_surgeon: null;
  race: string;
  insurance: string;
  gender: string;
  date: string;
  type: null;
  procedure_name: string;
  approach: string;
  minimally_invasive: string;
  navigation: string;
  robotic: string;
  indication: string;
  side: string;
  implant: string;
  implant_type: string;
  location: string;
  osteotomy: string;
  _fusion: string,
  fusion_levels: string,
  decompression_levels: string,
  interbody_fusion: string,
  extension_to_pelvis: string,
  morphogenic_protein: string,
  instrumentation: string,
  target: string,
  nerve: string,
  _procedure: string,
  tici_grade: string,
  access: string,
  vascular_closure_device: string,
  duration_of_radiation: string,
  duration_of_procedure: string,
  date_of_procedure: string,
  role: null;
  notes: string[];
  length_in_minutes: null;
  vendors: null;
  complications: null;
  findings: null;
  outcome: null;
  procedure_diagnosis: null;
  length_of_hospitalizations_in_days: null;
  follow_up_number: number;
  follow_up_period: string;
  additional_info: string;
}

export interface caseProcedureType {
  id: string | number,
  type: string,
  date: string,
  procedure_name: string,
  approach: string,
  minimally_invasive: string,
  navigation: string,
  robotic: string,
  indication: string,
  side: string,
  implant: string,
  implant_type: string,
  location: string,
  osteotomy: string,
  _fusion: string,
  fusion_levels: string,
  decompression_levels: string,
  interbody_fusion: string,
  extension_to_pelvis: string,
  morphogenic_protein: string,
  target: string,
  nerve: string,
  _procedure: string,
  tici_grade: string,
  access: string,
  vascular_closure_device: string,
  duration_of_radiation: string,
  duration_of_procedure: string,
  date_of_procedure: string,
  findings: string,
  vendors: string,
  complications: string,
  outcome: string,
}

export interface cptModifierType {
  id: string,
  description: string,
  percentage: number,
  additional_info: string
}

export interface mediaType {
  deleted_flag: boolean;
  image_name: string;
  id: string;
}

export interface rolesDataType {
  value: string;
  label: string;
}

export interface hospitalDataType {
  name: string;
  value?: string;
}

export interface genderListType {
  value: string;
  label: string;
}

export interface implantListType {
  value: string;
  label: string;
}

export const Permission = {
  MINE: 'MINE',
  ALL: 'ALL'
};
export type caseParams = {
  caseId: string;
};

export type planParams = {
  planId: string;
};
export type planNameParams = {
  planName: string;
};

export type currentPlanType = {
  planName: string;
};

export type codeType = {
  code: string;
};

export type keyType = {
  key: string;
};

export type doctorParams = {
  doctorId: string;
};

export const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];

export type registryParams = {
  registryId: string;
};
export const FieldsType = {
  Free_Text: 'Free text',
  Drop_down_list: 'Drop down list',
  Image: 'Image',
  Array: 'Array',
  Date: 'Date',
  Cpt: 'CPT',
  Diagnosis: 'Diagnosis'
};
export const requiredField = [
  {
    property: 'Patient first name',
    type: FieldsType.Free_Text,
    name: 'patient_first_name',
    optional: false
  },
  {
    property: 'Patient last name ',
    type: FieldsType.Free_Text,
    name: 'patient_last_name',
    optional: false
  },
  {
    property: 'Gender ',
    type: FieldsType.Drop_down_list,
    name: 'gender',
    optional: false
  },
  {
    property: 'Institute',
    type: FieldsType.Free_Text,
    name: 'institute',
    optional: false
  },
  {
    property: 'Procedure type ',
    type: FieldsType.Free_Text,
    name: 'type',
    optional: false
  },

  {
    property: 'Date of birth ',
    type: FieldsType.Date,
    name: 'date_of_birth',
    optional: false
  },
  {
    property: 'Date of surgery ',
    type: FieldsType.Date,
    name: 'date',
    optional: false
  }
];
export interface userInfoType {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  userRole: {
    id: number;
    niceName: string;
  };
  reports?: any[];
  profileImage: {
    hrefSmall: string;
    hrefOriginal?: string;
  };
  userProfile: {
    speciality: {
      name: string;
    };
  };
  userExperiences?: any[];
  userEducations?: any[];
  currentPositions?: any[];
  id: number;
  userCountryCode: {
    phoneCode: number;
    id?: number;
  };
  reimbursement? : ReimbursementType;
}

export interface ReimbursementType {
  practiceState: string,
  reimbursementModel: string,
  threshold: string,
  amount: number,
  rvusMultiplier: number,
}

export interface PasswordStatus {
  password: string;
  showPassword: boolean;
}

export interface FormValuesType {
  role: string;
  accepted_privacy_policy: boolean;
  accepted_terms: boolean;
  registered_from: string;
  countryCode: null;
  phone: string;
  email: string;
  first_name: string;
  last_name: string;
  password: string;
  accountType: string;
  business_name: string;
  business_description: string;
}

export interface registrFormStepOneValuesType {
  name: string;
  description: string;
}

export interface registrFormStepTwoValuesType {
  name: string;
  type: string;
  required: string;
  visible: string;
  value?: string;
}

export interface matchedType {
  length: number;
  offset: number;
}

export interface structuredLocationType {
  main_text: string;
  main_text_matched_substrings: matchedType[];
}

export interface tremsType {
  offset: number;
  value: string;
}

export interface PlaceType {
  description: string;
  place_id: string;
  reference: string;
  matched_substrings: matchedType[];
  structured_formatting: structuredLocationType;
  terms: tremsType[];
  type: string[];
}

export interface PositionType {
  address: string;
  deletedDate: string;
  deletedFlag: boolean;
  description: string;
  endDate: string;
  hospital: {
    address: string;
    googlePlaceId: string;
    id: number;
    latitude: number;
    longitude: number;
    name: string;
  };
  id: number;
  speciality: {
    id: number;
    name: string;
  };
  startDate: string;
}

export interface EducationType {
  created: string;
  degree: {
    code: string;
    id: number;
    name: string;
    order: number;
  };
  deletedDate: string;
  deletedFlag: boolean;
  description: string;
  endDate: string;
  id: number;
  institutionName: string;
  startDate: string;
  updated: string;
}

export interface CountryCodeType {
  flag: string;
  id: number;
  nice_name: string;
  phone_code: number;
}

export interface AlertMeassageType {
  type: string;
  message: string;
}

export interface RegistryDataType {
  name: string;
  description: string;
}

export interface RegistryDataCountType {
  name: string;
  countShared: number;
}
