
import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import contactUsStyle from './Styles';
import 'flag-icon-css/css/flag-icon.min.css';
import { useForm } from 'react-hook-form';
import * as api from '../../../services/apiService';
import envelop from '../../../assets/img/Ms-contact-envelop-icon.png';
import phoneIcon from '../../../assets/img/Ms-contact-phone-icon.png';
import Footer from '../../../components/footer/Footer';
import history from '../../../utils/history';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

const ContactUs = () => {
  const classes = contactUsStyle();
  const [countries, setCountries] = React.useState([]);
  const [alertMessage, setAlertMessage] = React.useState<any[]>([]);
  const [showAlertMessage, setShowAlertMessage] = React.useState(false);
  const [countryCode, setCountryCode] = React.useState({});
  const [selectedCountry, setSelectedCountry] = React.useState('');
  let contactSchema = yup.object().shape({
    email: yup
      .string()
      .trim()
      .email('Must use valid email address')
      .required('Email is required'),
    first_name: yup.string().required('First name is required'),
    last_name: yup.string().required('First name is required'),
    subject: yup.string().required('Subject is required'),
    message: yup.string().required('Message is required')
  });
  let possition = '-32px 0px';
  const { register, handleSubmit, errors, reset, control } = useForm({
    defaultValues: {
      first_name: '',
      last_name: '',
      email: '',
      subject: '',
      phone: '',
      countryCode: '',
      message: '',
      agreed: false,
    },
    resolver: yupResolver(contactSchema),
    mode: 'onSubmit',
    reValidateMode: 'onSubmit'
  });

  const [formValues, setFormValues] = useState<any>({
    first_name: '',
    last_name: '',
    email: '',
    subject: '',
    message: '',
    agreed: false
  });

  const getCountries = async () => {
    if (countries.length === 0) {
      const { data } = await api.getCountries();
      setCountries(data);
    }
  };

  const isValidEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
  };

  const validateForm = () => {
    if (formValues.first_name === '') {
      setAlertMessage(['error', 'First Name is required']);
      setShowAlertMessage(true);
      setTimeout(() => {
        setShowAlertMessage(false);
      }, 2000);
      return false;
    } else if (formValues.last_name === '') {
      setAlertMessage(['error', 'Last Name is required']);
      setShowAlertMessage(true);
      setTimeout(() => {
        setShowAlertMessage(false);
      }, 2000);
      return false;
    } else if (formValues.email === '') {
      setAlertMessage(['error', 'Email is required']);
      setShowAlertMessage(true);
      setTimeout(() => {
        setShowAlertMessage(false);
      }, 2000);
      return false;
    } else if (!isValidEmail(formValues.email)) {
      setAlertMessage(['error', 'Invalid email address']);
      setShowAlertMessage(true);
      setTimeout(() => {
        setShowAlertMessage(false);
      }, 2000);
      return false;
    } else if (formValues.subject === '') {
      setAlertMessage(['error', 'Subject is required']);
      setShowAlertMessage(true);
      setTimeout(() => {
        setShowAlertMessage(false);
      }, 2000);
      return false;
    } else if (formValues.message === '') {
      setAlertMessage(['error', 'Message is required']);
      setShowAlertMessage(true);
      setTimeout(() => {
        setShowAlertMessage(false);
      }, 2000);
      return false;
    } else if (formValues.agreed === false) {
      setAlertMessage(['error', 'Terms and Conditions must be accepted']);
      setShowAlertMessage(true);
      setTimeout(() => {
        setShowAlertMessage(false);
      }, 2000);
      return false;
    }
    return true;
  }

  const onContactSubmit = async (values) => {
    if (!validateForm()) {
      return;
    }
    const payload = {
      name: formValues.first_name + " " + formValues.last_name,
      email: formValues.email,
      subject: formValues.subject,
      message: formValues.message
    };

    const { status_code: statusCode, data } = await api.contactUs(payload);
    if (statusCode === api.status.SUCCESS) {
      setAlertMessage(['success', `${data.message}`]);
      setShowAlertMessage(true);
      setFormValues({
        first_name: '',
        last_name: '',
        email: '',
        subject: '',
        message: '',
        agreed: false,
      })
      setTimeout(() => {
        setShowAlertMessage(false);
      }, 2000);
    } else {
      setAlertMessage(['error', `${data.message}`]);
      setShowAlertMessage(true);
      setTimeout(() => {
        setShowAlertMessage(false);
      }, 2000);
    }
  };
  useEffect(() => { }, [countries]);
  useEffect(() => {
    getCountries();
  }, []);

  const onSubmitHandler = handleSubmit(onContactSubmit);

  const handleValueChange = (e) => {
    const newValue = e.target.type === 'checkbox' ? e.target.checked : e.target.value;

    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      [e.target.name]: newValue,
    }));
  };


  return (
    <div>
      <Snackbar open={showAlertMessage} color={alertMessage[0]}>
        <Alert onClose={() => setShowAlertMessage(false)} severity={alertMessage[0]}>
          {alertMessage[1]}
        </Alert>
      </Snackbar>
      <section className="breadCrums">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="crumsHeading">
                <h1>Contact Us</h1>
                <ul className="d-flex align-items-center justify-content-center">
                  <li><a onClick={() => history.push('/')} className="breadCrumsLink home pointer">Home </a></li>
                  <li><a onClick={() => history.push('/contact')} className="breadCrumsLink pointer"> &gt; Contact Us</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="contactUs">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="contactHeading">
                <h1>For support and other inquiries, our team is ready to help you.</h1>
              </div>
              <div className="contactForm" onChange={handleValueChange}>
                <form onSubmit={onSubmitHandler}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="firstName">First Name <sup>*</sup></label>
                        <input name="first_name" value={formValues.first_name} type="text" id="firstName" className="form-control" placeholder="First Name" required></input>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="lastName">Last Name <sup>*</sup></label>
                        <input name="last_name" value={formValues.last_name} type="text" id="lastName" className="form-control" placeholder="Last Name" required></input>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="emailAddress">Email Address <sup>*</sup></label>
                        <input name="email" value={formValues.email} type="email" id="emailAddress" className="form-control" placeholder="Your Email" required></input>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="subject">Subject <sup>*</sup></label>
                        <input name="subject" value={formValues.subject} type="text" id="subject" className="form-control" placeholder="Subject" required></input>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <label htmlFor="message">Message <sup>*</sup></label>
                        <textarea name="message" value={formValues.message} id="message" className="form-control" placeholder="Your Message..." cols={30} rows={8} required></textarea>
                      </div>
                    </div>
                  </div>
                  <div className="formSubmission d-md-flex justify-content-between align-items-center">
                    <div className="form-group mb-md-0">
                      <input name="agreed" checked={formValues.agreed} type="checkbox" id="conditions"></input>
                      <label htmlFor="conditions">Agree on our <a href="#">terms and condition</a> for using your submitted data?</label>
                    </div>
                    <div className="form-group mb-0 text-md-left text-center">
                      <button type="submit" onClick={onContactSubmit} className='form-control bgBlue login-btn pointer' style={{ width: '200px' }}>Send Message</button>
                    </div>
                  </div>
                </form>
              </div>
              <div className="contactCards">
                <div className="row">
                  <div className="col-md-12">
                    <div className="coardInfo">
                      <div className="cardIcon">
                        <img src={envelop} alt=""></img>
                      </div>
                      <div className="cardText">
                        <p><a href="mailto:info@mslogger.com">info@mslogger.com</a></p>
                        <p><a href="mailto:support@mslogger.com">support@mslogger.com</a></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default ContactUs;
