import { makeStyles, Theme, createStyles } from '@material-ui/core';

export const requestDemoStyle = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            padding: 17,
            position: 'relative',
        },
        cursor: {
            cursor: 'pointer'
        },
        root: {
            '&$checked': {
                color: '#ffad2c'
            }
        },
        checked: {},
        emptyView: {
            marginTop: '14%',
            display: 'flex',
            justifyContent: 'center'
        },
        checkBoxColor: {
            color: '#ffad2c'
        },
        centerText: {
            textAlign: 'center'
        },

        itemValue: {
            padding: '3px 0px',
            display: 'block'
        },
        icon: {
            position: 'absolute',
            top: 15,
            right: 14,
            color: '#aaaaaa'
        },
        search: {
            width: 180,
            [theme.breakpoints.down('xs')]: {
                width: '100%'
            },
            marginLeft: 16
        },
        searchBox: {
            display: 'flex',
            alignItems: 'center'
        },
        header: {
            width: '100%',
            textAlign: 'center'
        },
        buttonsContainer: {
            display: 'flex',
            justifyContent: 'space-evenly'
        },
        resultBox: {
            height: '50vh',
            overflowY: 'revert',
            padding: 18,
            paddingTop: 0
        },
        resultBoxWithBorder: {
            height: '50vh',
            overflowY: 'scroll',
            padding: 18,
            paddingTop: 0,
            border: '1px solid rgba(0, 0, 0, 0.16)'
        },
        footerButton: {
            width: '90%'
        },
        row: {
            cursor: 'pointer',
            '&:hover': {
                backgroundColor: '#e6f7ff',
            },
        },
    })
);
