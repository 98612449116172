import { create } from 'apisauce';
import { toCamelCase } from '../utils';
import { removeCookie, setCookie } from '../utils/sessionUtils';
import { setUser } from '../utils/index';

const apiSauce = create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
  credentials: 'same-origin'
});
export const status = {
  SUCCESS: 200,
  INVALID_INPUT: 600,
  INTERNAL_SERVER_ERROR: 500,
  UNAUTHENTICATED: 401,
  UNAUTHORIZED: 403,
  RESOURCE_NOT_FOUND: 600,
  INVALID_CREDENTIALS: 601,
  USER_ALREADY_EXISTS: 602,
  USER_NOT_VERIFIED: 603,
  USER_NOT_ALLOWED_TO_RESEND: 604,
  NPI_ALREADY_USED: 607,
  PIN_AUTHENTICATION_FAILED: 608,
  USER_EMAIL_NOT_VERIFIED: 611,
  USER_CHOSE_OLD_PASSWORD: 612,
  USER_DOCUMENT_NOT_VERIFIED: 613,
  INVALID_PHONE_NUMBER: 614,
  INVALID_VERIFICATION_CODE: 615,
  NO_DATA_TO_EXPORT: 617,
  INCORRECT_OLD_PASSWORD: 618,
  UPGRADE_PLAN_ERROR: 619,
  ERROR: 600,
  BAD_REQUEST: 400,
  ERROR_UPDATE: 621
};

const apiKeys = ['post', 'get', 'delete', 'put'];
const api = {};

apiSauce.addResponseTransform((response) => {
  let errors;
  const { data = {} } = response;
  if (response.ok) {
    const { status_code: statusCode } = data;

    if (statusCode !== status.SUCCESS) {
      const responseData = data.data;
      if (statusCode === status.USER_NOT_VERIFIED) {
      } else if (statusCode === status.INVALID_INPUT) {
        const validationErros = responseData.validation_errors || [];
        validationErros.forEach(({ error, field }) => {
          errors[field] = error;
        });
      } else if (statusCode === status.USER_NOT_ALLOWED_TO_RESEND) {
      } else if (statusCode === status.INVALID_CREDENTIALS) {
      } else if (statusCode === status.UPGRADE_PLAN_ERROR) {
      } else if (statusCode === status.ERROR_UPDATE) {
      } else if (statusCode === status.INTERNAL_SERVER_ERROR) {
      } else if (statusCode === status.UNAUTHORIZED) {
      } else if (statusCode === status.INVALID_PHONE_NUMBER) {
      } else if (statusCode === status.USER_ALREADY_EXISTS) {
      } else if (statusCode === status.NPI_ALREADY_USED) {
      } else if (statusCode === status.USER_CHOSE_OLD_PASSWORD) {
      } else if (statusCode === status.INVALID_VERIFICATION_CODE) {
      } else if (statusCode === status.UNAUTHENTICATED) {
        removeCookie('user');
        localStorage.clear();
        document.location.href = "/";
      } else if (statusCode === status.NO_DATA_TO_EXPORT) {
      } else if (statusCode === status.INCORRECT_OLD_PASSWORD) {
      } else {
        if (typeof responseData === 'string') {
          errors = { general: responseData };
        }
      }
    }
    data.errors = errors;
  } else {
    errors = { general: 'Unexpected error' };
    response.data = { ...data, errors };
  }
});
apiKeys.forEach((key) => {
  const modified = async (...args) => {
    const response = await apiSauce[key](...args);
    const { data } = response;

    const { errors } = data;
    if (errors) {
      throw errors;
    }
    return response;
  };
  api[key] = modified.bind(apiSauce);
});

export const NPIValidator = async ({ NPI }) => {
  const { data } = await api.get(`/npiregistry/${NPI}`);
  return data;
};

export const getCountries = async () => {
  const { data } = await api.get('/country_codes_lookups');
  return data;
};

export const getRoles = async () => {
  const { data } = await api.get('/role_lookups');
  return data;
};

export const getUserRoles = async () => {
  const { data } = await api.get('/user_roles_lookups');
  return data;
};

export const getCPTModifiers = async () => {
  const { data } = await api.get('/cpt_modifiers_lookups');
  return data;
}

export const getSpecialites = async () => {
  const { data } = await api.get('/specialities');
  return data;
};

export const getDegrees = async () => {
  const { data } = await api.get('/degrees');
  return data;
};

export const login = async (payload) => {
  var offsetInHours = new Date().getTimezoneOffset() / 60;
  payload.time_offset = offsetInHours;
  const { data } = await api.post('/login', payload);
  return data;
};

export const logout = async () => {
  const { data } = await api.post('/logout');
  return data;
};

export const signUp = async (payload) => {
  const { data } = await api.post('/registration', payload);
  data.verified = false;
  setCookie('user', JSON.stringify(data));
  localStorage.setItem('user', JSON.stringify(data));
  setUser(data);
  return data;
};

export const updateUserInfo = async (userId, payload) => {
  const { data } = await api.put(`/users/${userId}`, payload);
  return data;
};

export const validatePhone = async (payload) => {
  const { data } = await api.post('/phone_validation', payload);
  return data;
};

export const validateEmail = async (payload) => {
  const { data } = await api.post('/email_validation', payload);
  return data;
};

export const getUserInfo = async ({ userId, toCamel }) => {
  const { data } = await api.get(`/users/${userId}`);
  return toCamel ? toCamelCase(data) : data;
};

export const getMyUser = async (userId) => {
  const { data } = await api.get(`/users/${userId}/staff`);
  return data;
};

export const setUserInfo = async (userId, payload) => {
  const { data } = await api.put(`/users/${userId}`, payload);
  return data;
};

export const getUserVerification = async ({ userId }) => {
  const { data } = await api.get(`/users/${userId}/verification`);
  return data;
};

export const getDoctors = async (userId, filter) => {
  const { data } = await api.get(`/users/${userId}/doctors?filter=${filter}`);
  return data;
};

export const handleInvitation = async (userId, payload) => {
  const { data } = await api.put(
    `/users/${userId}/access_users/invitation`,
    payload
  );
  return data;
};

export const changePassword = async (userId, payload) => {
  const { data } = await api.post(`/users/${userId}/change_password`, payload);
  return data;
};

export const inviteUser = async (userId, payload) => {
  const { data } = await api.post(`/users/${userId}/access_users`, payload);
  return data;
};

export const declineInvitation = async (userId, payload) => {
  const { data } = await api.put(
    `/users/${userId}/access_users/revoke_user`,
    payload
  );
  return data;
};

export const searchUserSection = async (userId, key) => {
  const { data } = await api.get(
    `/users/${userId}/staff?filter=MY_USERS&search=` + key
  );
  return data;
};

export const updatePermission = async (userId, payload) => {
  const { data } = await api.put(
    `/users/${userId}/access_users/change_permission`,
    payload
  );
  return data;
};

export const forgetPassword = async (payload) => {
  const { data } = await api.post('/forget_password', payload);
  return data;
};

export const resetPassword = async (payload) => {
  const { data } = await api.post('/reset_password', payload);
  return data;
};

export const verifyAccount = async (payload) => {
  const { data } = await api.post(`/verification`, payload);
  return data;
};
export const procedures_import = async (files, user_id) => {
  const formData = new FormData();

  formData.append('csv_file', files[0]);

  formData.append('user_id', user_id);
  const { data } = await api.post(`/procedures_import`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
  return data;
};
export const procedures_import_shared = async (files, doctorId, user_id) => {
  const formData = new FormData();

  formData.append('csv_file', files[0]);

  formData.append('user_id', user_id);
  formData.append('doctor_id', doctorId);
  const { data } = await api.post(`/procedures_import`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
  return data;
};
export const procedures_import_registry = async (files, registryId) => {
  const formData = new FormData();

  formData.append('csv_file', files[0]);

  const { data } = await api.post(
    `/registries/${registryId}/procedures/import-from-csv`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  );
  return data;
};

export const profile_media = async (files, user_id) => {
  const formData = new FormData();

  formData.append('image_file', files[0]);

  const { data } = await api.post(`/users/${user_id}/media`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
  return data;
};

export const contactUs = async (payload) => {
  const { data } = await api.post('/contacts', payload);
  return data;
};

export const requestDemo = async (payload) => {
  const { data } = await api.post('/demo_request', payload);
  return data;
};

export const resendVerificationCode = async (userId) => {
  const { data } = await api.get(
    `/users/${userId}/verification_resend?registered_from=WEB`
  );

  return data;
};

export const deleteRegistryImage = async (registryId, uuid) => {
  const { data } = await api.get(
    `/registries/${registryId}/Procedures/delete-image?uuid=${uuid}`
  );

  return data;
};

export const updateProfile = async (userId, payload) => {
  const { data } = await api.put(`/users/${userId}`, payload);
  return data;
};

export const addExperience = async (userId, payload) => {
  const { data } = await api.post(`/users/${userId}/experience`, payload);
  return data;
};

export const getReports = async (userId, page, pageSize) => {
  const { data } = await api.get(
    `/users/${userId}/procedures/reports?page=` +
    page +
    '&page_size=' +
    pageSize
  );
  return data;
};

export const getPindingSubscription = async () => {
  const { data } = await api.get(`plans/next-plan`);
  return data;
};

export const getDashboardNumber = async () => {
  const { data } = await api.get(`/users/dashboard`);
  return data;
};

export const getSharedDashboardNumber = async (doctorId) => {
  const { data } = await api.get(`/dashboard/${doctorId}`);
  return data;
};

export const editExperience = async (userId, experience_id, payload) => {
  const { data } = await api.put(
    `/users/${userId}/experience/${experience_id}`,
    payload
  );
  return data;
};

export const deleteExperience = async (userId, experience_id) => {
  const { data } = await api.delete(
    `/users/${userId}/experience/${experience_id}`
  );
  return data;
};

export const addEducation = async (userId, payload) => {
  const { data } = await api.post(`/users/${userId}/education`, payload);
  return data;
};

export const editEducation = async (userId, education_id, payload) => {
  const { data } = await api.put(
    `/users/${userId}/education/${education_id}`,
    payload
  );
  return data;
};

export const deleteEducation = async (userId, education_id) => {
  const { data } = await api.delete(
    `/users/${userId}/education/${education_id}`
  );
  return data;
};

export const search = async (path, keyword, limit = 30) => {
  if (path === 'places') {
    const { data } = await api.get(
      'https://maps.googleapis.com/maps/api/place/findplacefromtext/json',
      {
        key: 'AIzaSyCmKRYmEuGJPNFbpworGazTY7Do8WG2S2s',
        inputtype: 'textquery',
        fields: 'place_id,geometry,name,formatted_address',
        input: keyword
      }
    );

    let { candidates } = data;
    candidates = candidates.map((candidate) => {
      const { geometry, formatted_address, name, place_id } = candidate;
      const { location } = geometry;
      const hospital = {
        id: place_id,
        value: name,
        name,
        place_id,
        address: formatted_address,
        longitude: location.lng,
        latitude: location.lat
      };
      return hospital;
    });
    return { status_code: 200, data: candidates };
  } else {
    const { data } = await api.get(`/${path}`, { keyword, limit });
    return data;
  }
};

export const onTermSearch = async (path, search_term, limit = 30) => {
  const { data } = await api.get(`/${path}`, { search_term, limit });
  return data;
};

export const getUserHospitals = async (userId) => {
  const { data } = await api.get(`/users/${userId}/current_hospitals`);
  return data;
};

export const getGenders = async () => {
  const { data } = await api.get('/gender_lookups');
  return data;
};

export const getRaceList = async () => {
  const { data } = await api.get('/race_lookups');
  return data;
}

export const getTICIGradeList = async () => {
  const { data } = await api.get('/tici_grade_lookups');
  return data;
}

export const getInsuranceList = async () => {
  const { data } = await api.get('/insurance_lookups');
  return data;
}

export const getNeurosurgeryProcedureTypeList = async () => {
  const { data } = await api.get('/proceduretype_neurosurgery_lookups');
  return data;
}

export const getInterventionalProcedureTypeList = async () => {
  const { data } = await api.get('/proceduretype_neurointerventional_lookups');
  return data;
}

export const getBedsideProcedureTypeList = async () => {
  const { data } = await api.get('/proceduretype_bedside_lookups');
  return data;
}

export const getRadiologyProcedureTypeList = async () => {
  const { data } = await api.get('/proceduretype_radiology_lookups');
  return data;
}

export const getSpineApproachList = async () => {
  const { data } = await api.get('/approach_spine_lookups');
  return data;
}

export const getCranialApproachList = async () => {
  const { data } = await api.get('/approach_cranial_lookups');
  return data;
}

export const getCranialIndicationList = async () => {
  const { data } = await api.get('/indication_cranial_lookups');
  return data;
}

export const getSpineIndicationList = async () => {
  const { data } = await api.get('/indication_spine_lookups');
  return data;
}

export const getFunctionalIndicationList = async () => {
  const { data } = await api.get('/indication_functional_lookups');
  return data;
}

export const getNervesIndicationList = async () => {
  const { data } = await api.get('/indication_nerves_lookups');
  return data;
}

export const getInterventionalIndicationList = async () => {
  const { data } = await api.get('/indication_neurointerventional_lookups');
  return data;
}

export const getInterIndicationList = async () => {
  const { data } = await api.get('/indication_interventional_lookups');
  return data;
}

export const getRadiologyIndicationList = async () => {
  const { data } = await api.get('/indication_radiology_lookups');
  return data;
}

export const getSidesList = async () => {
  const { data } = await api.get('/side_lookups');
  return data;
}

export const getSpineLocationList = async () => {
  const { data } = await api.get('/location_spine_lookups');
  return data;
}

export const getDissectionLocationList = async () => {
  const { data } = await api.get('/dissection_location_lookups');
  return data;
}

export const getStrokeLocationList = async () => {
  const { data } = await api.get('/stroke_location_lookups');
  return data;
}

export const getBleedingLocationList = async () => {
  const { data } = await api.get('/bleeding_location_lookups');
  return data;
}

export const getAVMLocationList = async () => {
  const { data } = await api.get('/avm_location_lookups');
  return data;
}

export const getHydrocephalusLocationList = async () => {
  const { data } = await api.get('/hydrocephalus_location_lookups');
  return data;
}

export const getFractureLocationList = async () => {
  const { data } = await api.get('/fracture_location_lookups');
  return data;
}

export const getHematomaLocationList = async () => {
  const { data } = await api.get('/hematoma_location_lookups');
  return data;
}

export const getAneurysmLocationList = async () => {
  const { data } = await api.get('/aneurysm_location_lookups');
  return data;
}

export const getAneurysmLocationInterList = async () => {
  const { data } = await api.get('/aneurysm_location_inter_lookups');
  return data;
}

export const getTumorLocationList = async () => {
  const { data } = await api.get('/tumor_location_lookups');
  return data;
}

export const getOsteotomyList = async () => {
  const { data } = await api.get('/osteotomy_lookups');
  return data;
}

export const deleteCaseProcedureById = async (userId, procedureId, caseProcedureId) => {
  const { data } = await api.delete(
    `/users/${userId}/procedures/${procedureId}/case_procedure/${caseProcedureId}`
  );
  return data;
};

export const getStatesList = async () => {
  const { data } = await api.get('/states_lookups');
  return data;
}

export const getReimbursementModelList = async () => {
  const { data } = await api.get('/reimbursement_model_lookups');
  return data;
}

export const getThresholdList = async () => {
  const { data } = await api.get('/threshold_lookups');
  return data;
}

export const deleteReimbursement = async (userId) => {
  const { data } = await api.delete(`/users/${userId}/reimbursement`);
  return data;
}

export const updateReimbursement = async (userId, payload) => {
  const { data } = await api.put(`/users/${userId}/reimbursement`, payload);
  return data;
}

export const searchCases = async (
  userId,
  page,
  pageLimit,
  { filter, search_by, value }
) => {
  const { data } = await api.get(
    `/users/${userId}/procedures?page=` + page + '&page_size=' + pageLimit,
    {
      filter,
      search_by,
      value
    }
  );
  return data;
};

export const validateCoupon = async (code, price, flow) => {
  const { data } = await api.get(
    `/coupons/validate?code=` + code + '&price=' + price + '&flow=' + flow
  );
  return data;
};

export const searchRegistryCases = async (
  registryId,
  page,
  pageLimit,
  { filter, search_by, value }
) => {
  const { data } = await api.get(
    `/registries/${registryId}/procedures?page=` +
    page +
    '&page_size=' +
    pageLimit,
    {
      filter,
      search_by,
      value
    }
  );
  return data;
};
export const getRegistries = async (group, sortBy, orderBy, page, size) => {
  const { data } = await api.get(
    `/registries?order_by=` +
    orderBy +
    '&sort_by=' +
    sortBy +
    '&group=' +
    group +
    '&page=' +
    page +
    '&page_size=' +
    size
  );
  return data;
};

export const getAvailableUser = async (searchValue) => {
  const { data } = await api.get(
    `/users/users-available?search_value=${searchValue}`
  );
  return data;
};

export const getShareUser = async (registryId, searchValue) => {
  const { data } = await api.get(
    `/registries/${registryId}/users/users-available?search_value=${searchValue}`
  );
  return data;
};

export const getAcceptedUser = async (registryId, searchValue) => {
  const { data } = await api.get(
    `/registries/${registryId}/users?status=ACCEPTED&search_value=${searchValue}`
  );
  return data;
};

export const getPendingUser = async (registryId, searchValue) => {
  const { data } = await api.get(
    `/registries/${registryId}/users?status=PENDING&search_value=${searchValue}`
  );
  return data;
};

export const exportCases = async (userId, { filter, search_by, value }) => {
  const { data } = await api.get(
    `/users/${userId}/procedures/export?type=PDF`,
    {
      filter,
      search_by,
      value
    }
  );
  return data;
};

export const getPlanByBraintreeId = async (braintree_plan_id) => {
  const { data } = await api.get(
    `/plans/get-braintree-plan?braintree_plan_id=` + braintree_plan_id + ''
  );
  return data;
};

export const getPlans = async () => {
  const { data } = await api.get(`/plans`);
  return data;
};

export const getCurrentPlan = async () => {
  const { data } = await api.get(`/plans/current-plan`);
  return data;
};

export const getUserToken = async () => {
  const { data } = await api.get(`/payments/client-token`);
  return data;
};

export const getNewUserToken = async () => {
  const { data } = await api.get(`/payments/anonymous-client-token`);
  return data;
};

export const deleteSubscription = async (subscription_id) => {
  const { data } = await api.delete(`/subscriptions/${subscription_id}`);
  return data;
};

export const createSubscription = async (payload) => {
  const { data } = await api.post(`/subscriptions`, payload);
  return data;
};

export const registryName = async () => {
  const { data } = await api.get(`/registries?group=ALL&order_by=created`);
  return data;
};

export const exportToRegistry = async (registryId, payload) => {
  const { data } = await api.post(
    `/registries/${registryId}/procedures/import-from-dashboard`,
    payload
  );
  return data;
};

export const addRegistry = async (payload) => {
  const { data } = await api.post(`/registries`, payload);
  return data;
};

export const exportPDFCase = async (userId, payload) => {
  const { data } = await api.post(
    `/users/${userId}/procedures/export`,
    payload
  );
  return data;
};

export const updateNotes = async (userId, procedureId, notes) => {
  const { data } = await api.put(`/users/${userId}/procedures/${procedureId}/notes/procedure_all_notes`, { notes: notes });
  return data;
}

export const exportRegistryPDFCase = async (registryId, payload) => {
  const { data } = await api.get(
    `/registries/${registryId}/procedures/export`,
    payload
  );
  return data;
};

export const exportRegistryExcelCase = async (registryId, payload) => {
  const { data } = await api.get(
    `/registries/${registryId}/procedures/export`,

    payload
  );
  return data;
};

export const exportExcelCase = async (userId, payload) => {
  const { data } = await api.post(
    `/users/${userId}/procedures/export`,

    payload
  );
  return data;
};

export const exportExcelCases = async (
  userId,
  { filter, search_by, value }
) => {
  const { data } = await api.get(
    `/users/${userId}/procedures/export?type=EXCEL`,
    {
      filter,
      search_by,
      value
    }
  );
  return data;
};

export const getOldCases = async (userId) => {
  const data = await searchCases(userId, { filter: 'OLD' });
  return data;
};

export const getLastWeekCases = async (userId) => {
  const data = await searchCases(userId, { filter: 'WEEK' });
  return data;
};

export const getAllCases = async (userId, page, pageLimit) => {
  const { data } = await api.get(
    `/users/${userId}/procedures?page=` + page + '&page_size=' + pageLimit
  );
  return data;
};

export const getActiveCases = async (userId, page, pageLimit) => {
  const data = await searchCases(userId, page, pageLimit, { filter: 'ACTIVE' });
  return data;
};

export const getDischargedCases = async (userId, page, pageLimit) => {
  const data = await searchCases(userId, page, pageLimit, {
    filter: 'DISCHARGED'
  });
  return data;
};

export const createProcedure = async (userId, formData) => {
  const { data } = await api.post(`/users/${userId}/procedures`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
  return data;
};

export const createSharedProcedure = async (userId, formData) => {
  const { data } = await api.post(`/users/${userId}/procedures`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
  return data;
};

export const createRegistryProcedure = async (registryId, payload) => {
  const { data } = await api.post(
    `/registries/${registryId}/procedures`,
    payload
  );
  return data;
};

export const phoneValidation = async (payload) => {
  const { data } = await api.post('/phone_validation', payload);
  return data;
};

export const emailValidation = async (payload) => {
  const { data } = await api.post('/email_validation', payload);
  return data;
};

export const getProcedureMedia = async (userId, procedureId) => {
  const { data } = await api.get(
    `/users/${userId}/procedures/${procedureId}/media`
  );
  return data;
};

export const deleteProcedureMedia = async (userId, procedureId, mediaId) => {
  const { data } = await api.delete(
    `/users/${userId}/procedures/${procedureId}/media/${mediaId}`
  );
  return data;
};

export const getProcedureById = async (userId, procedureId) => {
  const { data } = await api.get(`/users/${userId}/procedures/${procedureId}`);
  return data;
};

export const getRegistryProcedureById = async (registryId, procedureId) => {
  const { data } = await api.get(
    `/registries/${registryId}/procedures/${procedureId}`
  );
  return data;
};

export const getRegistryById = async (registryId) => {
  const { data } = await api.get(`/registries/${registryId}`);
  return data;
};

export const verifyRegistry = async (registryId, code) => {
  const { data } = await api.get(
    `/registries/${registryId}/users/verify?code=${code}`
  );
  return data;
};

export const verifyInvitation = async (userId, key, code) => {
  const { data } = await api.get(
    `/users/${userId}/access_users/verify?key=${key}&code=${code}`
  );
  return data;
};

export const verifySharing = async (registryId, code) => {
  const { data } = await api.get(
    `/registries/${registryId}/users/verify?code=${code}`
  );
  return data;
};

export const deleteProcedureById = async (userId, procedureId) => {
  const { data } = await api.delete(
    `/users/${userId}/procedures/${procedureId}`
  );
  return data;
};

export const deleteRegistryProcedureById = async (registryId, procedureId) => {
  const { data } = await api.delete(
    `/registries/${registryId}/procedures/${procedureId}`
  );
  return data;
};

export const editProcedureById = async (userId, procedureId, payload) => {
  const { data } = await api.put(
    `/users/${userId}/procedures/${procedureId}`,
    payload
  );
  return data;
};

export const editSharedProcedureById = async (userId, procedureId, payload) => {
  const { data } = await api.put(
    `/users/${userId}/procedures/${procedureId}`,
    payload
  );
  return data;
};

export const UpdateRegistry = async (registry_id, payload) => {
  const { data } = await api.put(`/registries/${registry_id}`, payload);
  return data;
};
export const deleteRegistry = async (registry_id) => {
  const { data } = await api.delete(`/registries/${registry_id}`);
  return data;
};

export const UpdateSubscription = async (subscription_id, payload) => {
  const { data } = await api.put(`/subscriptions/${subscription_id}`, payload);
  return data;
};

export const editSharedUserPermission = async (registryId, userId, payload) => {
  const { data } = await api.put(
    `/registries/${registryId}/users/${userId}`,
    payload
  );
  return data;
};

export const deleteRegistryShareUser = async (registryId, deletedUserId) => {
  const { data } = await api.delete(
    `/registries/${registryId}/users/${deletedUserId}`
  );
  return data;
};

export const shareRegistry = async (registryId, payload) => {
  const { data } = await api.post(`/registries/${registryId}/users`, payload);
  return data;
};

export const editRegistryProcedureById = async (
  registryId,
  procedureId,
  payload
) => {
  const { data } = await api.put(
    `/registries/${registryId}/procedures/${procedureId}`,
    payload
  );
  return data;
};

export const deleteImage = async (userId, procedureId, mediaId) => {
  const { data } = await api.delete(
    `/users/${userId}/procedures/${procedureId}/media/${mediaId}`
  );
  return data;
};

export const updateImage = async (userId, procedureId, mediaId, payload) => {
  const { data } = await api.put(
    `/users/${userId}/procedures/${procedureId}/media/${mediaId}`,
    payload
  );
  return data;
};

export const uploadProfileMedia = async (userId, file, onUploadProgress) => {
  const formData = new FormData();

  formData.append('image_type', 'PROFILE_IMAGE');
  formData.append('image_file', file[0]);

  const { data } = await api.post(`/users/${userId}/media`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    onUploadProgress
  });
  return data;
};

export const config = {
  onUploadProgress: function (progressEvent) {
    var percentCompleted = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total
    );
  }
};

export const uploadProcedureMedia = async (
  userId,
  procedureId,
  files,
  onUploadProgress
) => {
  const formData = new FormData();
  files.forEach((file) => {
    formData.append('images', file);
  });
  const { data } = await api.post(
    `/users/${userId}/procedures/${procedureId}/media`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress
    }
  );
  return data;
};

export const uploadRegistryProcedureMedia = async (
  registryId,
  files,
  onUploadProgress
) => {
  const formData = new FormData();

  formData.append('image', files);

  const { data } = await api.post(
    `/registries/${registryId}/procedures/upload-image`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress
    }
  );
  return data;
};

export const uploadNotes = async (userId, procedureId, payload) => {
  const { data } = await api.post(
    `/users/${userId}/procedures/${procedureId}/notes`,
    payload
  );
  return data;
};

export const deleteNote = async (userId, procedureId, noteId) => {
  const { data } = await api.delete(
    `/users/${userId}/procedures/${procedureId}/notes/${noteId}`
  );
  return data;
};

export const getCPTSuggestions = async () => {
  const { data } = await api.get(`/cpt_suggestions_lookups`);
  return data;
}

export const getSuggestedCPTs = async (procedureName) => {
  const { data } = await api.get(`/cpt_lookups/suggestions?procedure_name=${procedureName}`);;
  return data;
}